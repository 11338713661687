import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { fuseAnimations } from '@fuse/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService, GlobalConfigurationService, ToastService, DialogService, VerificaApiService } from 'app/_services';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as portugues } from 'app/_i18n/pt-br';
import { cpfEhValido } from 'app/_helpers';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { FacebookLoginProvider, SocialAuthService } from 'angularx-social-login';
import { Observable } from 'rxjs';
import { TraducaoService } from 'app/_services/traducao.service';
import { setNavigationMenu } from 'app/navigation/navigation';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { Overlay } from '@angular/cdk/overlay';
import { PoliticaSegurancaComponent } from 'app/authentication/login/politica-seguranca/politica-seguranca.component';
import { TipoLogin } from 'app/_models/enums/tipo-login';
import { RoleUsuario } from 'app/_models/_enums/role-usuario';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [fuseAnimations],
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading: boolean = false;
    submitted: boolean = false;
    returnUrl: string;
    loginPorCpf: boolean = false;
    lembrarSenha: boolean = false;
    provider = new firebase.auth.GoogleAuthProvider();
    user: Observable<firebase.User>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _route: ActivatedRoute,
        private _router: Router,
        private _authenticationService: AuthenticationService,
        public fuseProgressBarService: FuseProgressBarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _globalConfigurationService: GlobalConfigurationService,
        private _afAuth: AngularFireAuth,
        private _angularFireAuth: AngularFireAuth,
        private _authServiceFace: SocialAuthService,
        private _toastService: ToastService,
        private _dialogService: DialogService,
        private _verificaApiService: VerificaApiService,
        private _i18n: TraducaoService,
        private _fuseNavigationService: FuseNavigationService,
        private _overlay: Overlay
    ) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };

        this._fuseTranslationLoaderService.loadTranslations(portugues);

        if (this._authenticationService.currentUserValue) {
            this._router.navigate(['/']);
        }
    }

    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.email]],
            cpf: ['', [cpfEhValido]],
            senha: ['', Validators.required],
        });

        this.returnUrl = '/inicio';

        this.carregarDadosConfiguracaoGlobal();

        if (this._authenticationService.currentUserValue) {
            if (this._authenticationService.currentUserValue.perfil != RoleUsuario.ADMINISTRADOR_AFILIADO) {
                this.returnUrl = '/inicio/indicacoes';
            }
            this._router.navigate([this.returnUrl]);
        }

        this._afAuth.authState.subscribe(() => {
            this.user = this._angularFireAuth.authState;
        });
    }

    get form(): {
        [key: string]: AbstractControl;
    } {
        return this.loginForm.controls;
    }

    async onSubmit(): Promise<void> {
        this.submitted = true;

        if (this.loginForm.invalid) {
            return;
        }

        const identificacao = this.loginPorCpf ? this.form.cpf.value : this.form.email.value;
        const tipoLogin = this.loginPorCpf ? TipoLogin.CPF : TipoLogin.Email;
        this.fuseProgressBarService.show();

        if (!(await this._verificaApiService.verificarApiOnline())) {
            this.fuseProgressBarService.hide();
            this._toastService.mensagemError(this._i18n.get('MENSAGENS.NAOFOIPOSSIVELLOGARAPIFORADOAR'));
            return;
        }
        this._authenticationService
            .login(identificacao, this.form.senha.value, tipoLogin)
            .pipe(first())
            .subscribe(
                () => {
                    setNavigationMenu(this._authenticationService, this._fuseNavigationService);
                    this._setarRotaAposLogin();
                    this._router.navigate([this.returnUrl]);
                    this._fuseTranslationLoaderService.loadTranslations(portugues);
                    this.fuseProgressBarService.hide();
                    this.gravarDadosConfiguracaoGlobal(tipoLogin, identificacao, this.form.senha.value);
                },
                () => {
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemError(this._i18n.get('MENSAGENS.USUARIOSENHAINVALIDOS'));
                }
            );
    }

    public trocarTipoLogin(): void {
        setTimeout(() => {
            this.loginPorCpf = !this.loginPorCpf;

            this.form.cpf.setValue(undefined);
            this.form.email.setValue(undefined);
            this.form.cpf.clearValidators();
            this.form.email.clearValidators();

            if (this.loginPorCpf) {
                this.form.cpf.setValidators(cpfEhValido);
            } else {
                this.form.email.setValidators(Validators.email);
            }

            this.form.cpf.updateValueAndValidity();
            this.form.email.updateValueAndValidity();
        });
    }

    public carregarDadosConfiguracaoGlobal(): void {
        const config = this._globalConfigurationService.currentConfiguration;
        this.lembrarSenha = config.lembrarSenha;
        this.loginPorCpf = config.tipoLogin === TipoLogin.CPF;

        if (this.lembrarSenha) {
            const dadosLogin = atob(config.cfgLogin).split('¨');
            this.form.senha.setValue(dadosLogin[1]);
            if (this.loginPorCpf) {
                this.form.cpf.setValue(dadosLogin[0]);
            } else {
                this.form.email.setValue(dadosLogin[0]);
            }
        }
    }

    public gravarDadosConfiguracaoGlobal(tipoLogin: TipoLogin, identificacao: string, senha: string): void {
        const config = this._globalConfigurationService.currentConfiguration;

        config.lembrarSenha = this.lembrarSenha;
        config.tipoLogin = tipoLogin;

        if (this.lembrarSenha) {
            const cfg = btoa(`${identificacao}¨${senha}`);
            config.cfgLogin = cfg;
        } else {
            config.cfgLogin = '';
        }

        this._globalConfigurationService.currentConfiguration = config;
    }

    public async loginComGoogle(): Promise<void> {
        this.fuseProgressBarService.show();

        if (!(await this._verificaApiService.verificarApiOnline())) {
            this.fuseProgressBarService.hide();
            this._toastService.mensagemError(this._i18n.get('MENSAGENS.NAOFOIPOSSIVELLOGARAPIFORADOAR'));
            return;
        }

        try {
            const resultadoAuth = await firebase.auth().signInWithPopup(this.provider);

            if (resultadoAuth == null) {
                this.fuseProgressBarService.hide();
                this._toastService.mensagemError(this._i18n.get('MENSAGENS.NAOFOIPOSSIVELGOOGLE'));
            } else {
                // tslint:disable-next-line: no-any
                const profile = resultadoAuth.additionalUserInfo.profile as any;
                const identificacao = profile.id;
                const emailGoogle = profile.email;
                this._authenticationService
                    .login(identificacao, '*', TipoLogin.LoginGoogle, emailGoogle)
                    .pipe(first())
                    .subscribe(
                        () => {
                            setNavigationMenu(this._authenticationService, this._fuseNavigationService);
                            this._setarRotaAposLogin();
                            this._router.navigate([this.returnUrl]);
                            this._fuseTranslationLoaderService.loadTranslations(portugues);
                            this.fuseProgressBarService.hide();
                        },
                        (httpError) => {
                            setTimeout(() => {
                                this.fuseProgressBarService.hide();

                                if (httpError.status === 404) {
                                    this._dialogService.dialogPergunta(
                                        this._i18n.get('LABELS.ATENCAO'),
                                        this._i18n.get('MENSAGENS.NAOTEMCADGOOGLACADASTRAR'),
                                        null,
                                        () => {
                                            this._router.navigate(['/registrar'], {
                                                queryParams: {
                                                    idGoogle: identificacao,
                                                    userGoogle: btoa(JSON.stringify(resultadoAuth.user)),
                                                },
                                            });
                                        }
                                    );
                                } else {
                                    this._dialogService.dialogInformacao(
                                        this._i18n.get('LABELS.ATENCAO'),
                                        this._i18n.get('MENSAGENS.NAOFOIPOSSIVELGOOGLECREDENCIAIS')
                                    );
                                }
                            }, 200);
                        }
                    );
            }
        } catch (error) {
            this.fuseProgressBarService.hide();
            this._toastService.mensagemError(this._i18n.get('MENSAGENS.NAOFOIPOSSIVELGOOGLE'));
        }
    }

    public async loginComFacebook(): Promise<void> {
        this.fuseProgressBarService.show();

        if (!(await this._verificaApiService.verificarApiOnline())) {
            this.fuseProgressBarService.hide();
            this._toastService.mensagemError(this._i18n.get('MENSAGENS.NAOFOIPOSSIVELLOGARAPIFORADOAR'));
            return;
        }

        this._authServiceFace
            .signIn(FacebookLoginProvider.PROVIDER_ID)
            .then((result) => {
                if (result == null) {
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemError(this._i18n.get('MENSAGENS.NAOFOIPOSSIVELFACE'));
                } else {
                    const identificacao = result.id;
                    const email = result.email;

                    this._authenticationService
                        .login(identificacao, '*', TipoLogin.LoginFacebook, email)
                        .pipe(first())
                        .subscribe(
                            () => {
                                setNavigationMenu(this._authenticationService, this._fuseNavigationService);
                                this._setarRotaAposLogin();
                                this._router.navigate([this.returnUrl]);
                                this._fuseTranslationLoaderService.loadTranslations(portugues);
                                this.fuseProgressBarService.hide();
                            },
                            (httpError) => {
                                setTimeout(() => {
                                    this.fuseProgressBarService.hide();

                                    if (httpError.status === 404) {
                                        this._dialogService.dialogPergunta(
                                            this._i18n.get('LABELS.ATENCAO'),
                                            this._i18n.get('MENSAGENS.NAOTEMCADFACECADASTRAR'),
                                            null,
                                            () => {
                                                this._router.navigate(['/registrar'], {
                                                    queryParams: {
                                                        idFacebook: identificacao,
                                                        userFacebook: btoa(JSON.stringify(result)),
                                                    },
                                                });
                                            }
                                        );
                                    } else {
                                        this._dialogService.dialogInformacao(
                                            this._i18n.get('LABELS.ATENCAO'),
                                            this._i18n.get('MENSAGENS.NAOFOIPOSSIVELFACECREDENCIAIS')
                                        );
                                    }
                                }, 200);
                            }
                        );
                }
            })
            .catch((error) => {
                console.log(error);
                this.fuseProgressBarService.hide();
                this._toastService.mensagemError(this._i18n.get('MENSAGENS.NAOFOIPOSSIVELFACE'));
            });
    }

     // *** COMENTADO 07/02/2022 - ANTIGO MODAL DE POLITICA DE SEGURANÇA ***
    // NOVA POLITICA DE PRIVACIDADE DIRECIONA PARA: https://www.meuconsultorio.com/politica-de-privacidade/

    // public openPoliticaSeguranca(): void {
    //     this._dialogService.dialogGenerico(PoliticaSegurancaComponent, {
    //         scrollStrategy: this._overlay.scrollStrategies.noop(),
    //         autoFocus: false,
    //     });
    // }

    private _setarRotaAposLogin(): void {
        if (this._authenticationService.currentUserValue.perfil != RoleUsuario.ADMINISTRADOR_AFILIADO) {
            this.returnUrl = '/inicio/indicacoes';
        }
    }

    abrirWhatsapp(): void {
        window.open(`https://api.whatsapp.com/send?phone=5537998046516`);
    }
}
